<template>
  <div id="loginRoot" class="section">
    <img alt="SAGITEAM" src="@/assets/logo2.png">
    <h1 class="title">MJP = Manage Judge Proxy</h1>
    <div v-if="isLogining">
      <h1>登录中....</h1>
    </div>
    <b-field id="mjpSelector" v-if="!isLogining && isDev" class="container" label="选择API">
      <b-select icon="server-network" v-model="selectedAdminMJPIndex" expanded @input="onAdminMJPSelect">
        <option v-for="(o, index) in adminMJPServers"
          :value="index"
          :key="o.name">
          {{o.name}}
        </option>
      </b-select>
    </b-field>
    <div v-if="!isLogining" class="container">
      <form @submit.prevent="onUserSubmit" class="container">
        <b-field>
          <b-input v-model.trim="loginUid" placeholder="管理员用户名" icon="account" > </b-input>
        </b-field>
        <b-field >
          <b-input type="password" v-model.trim="loginPassword" placeholder="密码" icon="key"></b-input>
        </b-field>
        <b-field grouped>
          <p class="control">
            <button class="button is-success is-medium" type="submit">帐号登录</button>
          </p>
          <p class="control">
            <button class="button is-warning is-medium" type="button" @click="onOIDCClick">一键登录</button>
          </p>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import cache from '@/core/cache'
import type from '@/core/type'
import config from '@/core/config'

export default {
  created () {
    this.updateAdminMJPFromCache()
    // oidc 登录
    if (this.loginByOidc()) {
      return
    }
    if (this.loginByCachedUser()) {
      return
    }
    // 从缓存登录不成功，执行一次登出还原状态
    this.hub.logout()
    this.loginStatus = 1
  },
  data () {
    return {
      adminMJPServers: config.ADMIN_MJP_SERVERS,
      // 当前选择的 mjpadm 索引。0 代表正式服，1 代表测试服，2 代表本地服
      selectedAdminMJPIndex: 0,
      selectedAdminMJPServer: null,
      loginStatus: 0,
      loginUid: '',
      loginPassword: '',
      chromeTips: ''
    }
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development'
    },
    isLogining () {
      return this.loginStatus === 2
    }
  },
  methods: {
    /**
     * 检测 OIDC 登录信息，并进行登录。
     */
    loginByOidc () {
      const oidcLoginObj = cache.getValue('oidcLogin', false, true)
      if (oidcLoginObj) {
        console.log('LoginByOidc login with oidcLoginObj:', oidcLoginObj)
        // 清空缓存中的 OIDc 登录信息
        cache.removeValue('oidcLogin')
        return this.autoLogin(oidcLoginObj)
      }
      return false
    },
    /**
     * 检测本地是否有缓存的 user 信息
     * 并进行登录
     */
    loginByCachedUser () {
      // 检测本地 token 是否过期
      const user = cache.getUser()
      if (user) {
        if (cache.isExpire(user.expire)) {
          this.hub.logout()
          return false
        }
        console.log('Login by cached user:', user)
        return this.autoLogin(user)
      }
      return false
    },
    updateAdminMJPFromCache () {
      this.selectedAdminMJPIndex = cache.getAdminMJPIndex()
      this.selectedAdminMJPServer = this.adminMJPServers[this.selectedAdminMJPIndex]
      // AppHeader 需要这个事件
      this.hub.$emit('mjpadm-change', this.selectedAdminMJPServer)
    },
    onAdminMJPSelect (adminMJPIndex) {
      // 更新缓存中保存的 mjpadm 选择索引
      cache.setAdminMJPIndex(adminMJPIndex)
      this.updateAdminMJPFromCache()
    },
    login (user) {
      if (!user.accesstoken) {
        this.hub.alert('登录失败！')
        return false
      }
      this.saveUser(user)
      console.log('login user: %o', user)
      this.hub.$emit('login-success')
      this.$router.replace('/home')
      return true
    },
    saveUser (user) {
      user.typename = type.getTypeName(user.usertype)
      cache.setUser(user)
    },
    async autoLogin (loginObj) {
      const { uid, accesstoken, expire } = loginObj
      console.log(`autoLogin uid:${uid} accesstoken:${accesstoken}`)
      this.loginStatus = 2
      // 根据 accesstoken 和 userid 获取 user 的详细信息，直接传递 mjst 而不是使用 head
      try {
        await type.preload(accesstoken)
        const data = await this.mjp.get('/admin/get/', { idtype: 'adminuid', idvalue: uid, mjst: accesstoken })
        data.user.accesstoken = accesstoken
        data.user.expire = expire
        return this.login(data.user)
      } catch (error) {
        this.loginStatus = 1
      }
      return false
    },
    onOIDCClick () {
      const toOidc = `${this.adminMJPServers[this.selectedAdminMJPIndex].admapi}/admin/login/oidc/`
      console.log(toOidc)
      location.href = toOidc
    },
    async onUserSubmit () {
      this.loginStatus = 2
      const loginObj = { username: this.loginUid, password: this.loginPassword }

      try {
        const userObj = await this.mjp.post('/admin/login/username/', loginObj)
        delete userObj.error
        delete userObj.code
        delete userObj.message
        await type.preload(userObj.accesstoken)
        this.login(userObj)
      } catch (error) {
        this.loginStatus = 1
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>

form {
  width: 260px;
}

#mjpSelector {
  width: 260px;
}

#loginRoot {
  text-align: center;
}

#loginRoot > div {
  padding-bottom: 20px;
}
</style>
